import { motion } from 'framer-motion';

import { fadeInProps } from '../animations';
import { NewDesktopSection } from './NewDesktopSection';
import { ListingCard, NamedLink, PrimaryButton } from 'components';
import { useMediaQueries } from 'hooks/useMediaQueries';
import { cn } from 'util/cn';

function NewListingsSection({ className, listings = [] }: { className?: string; listings: any[] }) {
  const isDesktop = useMediaQueries({ viewport: 'small' });
  return isDesktop ? (
    <NewListingsSectionDesktop className={className} listings={listings} />
  ) : (
    <NewListingsSectionMobile className={className} listings={listings} />
  );
}

function NewListingsSectionDesktop({
  className,
  listings = [],
}: {
  className?: string;
  listings: any[];
}) {
  return (
    <NewDesktopSection
      heading={
        <>
          <h3>
            <span className="bg-custom-green text-1">Pre-loved</span>
          </h3>
          <h2>Recently added</h2>
        </>
      }
    >
      {listings.map(l => (
        <div className="flex-shrink-0 w-[250px] px-2 pt-4" key={l.id.uuid}>
          <ListingCard rootClassName="flex-shrink-0" listing={l} />
        </div>
      ))}
    </NewDesktopSection>
  );
}

function NewListingsSectionMobile({
  className,
  listings = [],
}: {
  className?: string;
  listings: any[];
}) {
  return (
    <motion.div className={cn('pt-5 pb-10 w-full grid gap-5', className)} {...fadeInProps}>
      <div className="flex flex-col gap-1 items-center">
        <h3 className="font-syne text-0 font-bold uppercase bg-custom-green">Pre-loved</h3>
        <h2 className="font-syne text-3 md:text-5 font-bold uppercase">Recently added</h2>
      </div>
      <div className="flex gap-2 overflow-x-auto px-2">
        {listings.map(l => (
          <ListingCard rootClassName="flex-shrink-0 w-[40vw]" key={l.id.uuid} listing={l} />
        ))}
      </div>
      <NamedLink name="SearchPage" className="flex justify-center">
        <PrimaryButton>Shop all</PrimaryButton>
      </NamedLink>
    </motion.div>
  );
}

export default NewListingsSection;
