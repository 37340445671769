import { useRef } from 'react';

import noImagePlaceholder from '../../../../assets/no-image-placeholder.png';
import { useGetUserSocials } from '../../../../hooks/api/users/useGetUserSocials';
import css from './FollowingSection.module.css';
import { CarouselNavigationButtons, NamedLink } from 'components';
import { RouteName } from 'routing/routeConfiguration';

const FollowingSection = () => {
  const { data: userSocials, isLoading } = useGetUserSocials();
  const following = userSocials?.following || [];
  const defaultFollowing = userSocials?.defaultFollowing || [];
  const results = isLoading
    ? new Array(8).fill({
        id: '',
        imageUrl: noImagePlaceholder,
        handle: '',
      })
    : [
        ...following,
        ...defaultFollowing.sort((a, b) =>
          a.id === '6681ae6f-5a19-4f43-83cb-31698ea11f84' ? -1 : 1
        ),
      ];

  const socialCirclesData = results.map((user, i) => ({
    id: user.id || `placeholder-${i}`,
    imageUrl: user.imageUrl,
    label: user.handle,
    linkProps: user.id ? { name: 'ProfilePage' as RouteName, params: { id: user.id } } : undefined,
  }));

  return <SocialCircles items={socialCirclesData} />;
};

export default FollowingSection;

export interface SocialCirclesProps {
  items: Array<{
    id: string;
    imageUrl: string;
    label: string;
    linkProps?: {
      name: RouteName;
      params: { [key: string]: string };
    };
  }>;
}

export const SocialCircles = ({ items }: SocialCirclesProps) => {
  const carouselRef = useRef<HTMLDivElement>(null);

  return (
    <div className={css.root}>
      <div className={css.items} ref={carouselRef}>
        {items.map((item, i) => {
          const Component = item.linkProps?.name ? NamedLink : 'div';
          const linkProps: any = item.linkProps || {};

          return (
            <Component key={item.id || i} className={css.item} {...linkProps}>
              <picture>
                <source srcSet={item.imageUrl} />
                <img src={noImagePlaceholder} alt={item.label} />
              </picture>
              <p>{item.label} </p>
            </Component>
          );
        })}
      </div>
      <CarouselNavigationButtons carouselRef={carouselRef} />
    </div>
  );
};
