import { motion } from 'framer-motion';

import { fadeInProps } from '../animations';
import { NewDesktopSection } from './NewDesktopSection';
import { ImageLazy } from 'components';
import { useMediaQueries } from 'hooks/useMediaQueries';

const FEATURED_IN_LIST = [
  {
    url: 'https://www.forbes.com/sites/brookerobertsislam/2024/07/17/how-startup-the-nold-uses-ai--sustainability-to-drive-gen-z-resale/',
    image: 'https://cdn.thenold.com/images/2024-10-23_10-03-06__Forbes_compressed.webp',
    blur: 'https://cdn.thenold.com/images/2024-10-23_10-03-06__Forbes_blur.webp',
    alt: 'Forbes',
  },
  {
    url: 'https://www.businessoffashion.com/articles/global-markets/whats-driving-eastern-europes-fashion-tech-boom/',
    image: 'https://cdn.thenold.com/images/2024-10-23_10-03-06__BOF_compressed.webp',
    blur: 'https://cdn.thenold.com/images/2024-10-23_10-03-06__BOF_blur.webp',
    alt: 'Business of Fashion',
  },
  {
    url: 'https://www.theindustry.fashion/peer-to-peer-platform-nold-raises-e1-million-to-power-next-generation-of-fashion-resale/',
    image: 'https://cdn.thenold.com/images/2024-10-23_10-03-06__Industry_compressed.webp',
    blur: 'https://cdn.thenold.com/images/2024-10-23_10-03-06__Industry_blur.webp',
    alt: 'The Industry Fashion',
  },
];

export const NewFeaturedSection = () => {
  const isDesktop = useMediaQueries({ viewport: 'medium' });
  return isDesktop ? <NewFeaturedSectionDesktop /> : <NewFeaturedSectionMobile />;
};

export const NewFeaturedSectionDesktop = () => {
  return (
    <NewDesktopSection heading="Featured in">
      {FEATURED_IN_LIST.map(item => (
        <a href={item.url} key={item.url} className="flex-1 h-[250px] grid place-items-center">
          <ImageLazy
            src={item.image}
            blurSrc={item.blur}
            alt={item.alt}
            objectFit="contain"
            className="max-w-[250px] w-full"
          />
        </a>
      ))}
    </NewDesktopSection>
  );
};

export const NewFeaturedSectionMobile = () => {
  return (
    <motion.div className="pt-5 pb-10 w-full grid gap-5" {...fadeInProps}>
      <div className="flex flex-col gap-1 items-center">
        <h2 className="font-syne text-3 md:text-5 font-bold uppercase">Featured in</h2>
      </div>
      <div className="flex px-1 max-w-[600px] mx-auto">
        {FEATURED_IN_LIST.map(item => (
          <a href={item.url} key={item.url} className="grid place-items-center">
            <ImageLazy src={item.image} blurSrc={item.blur} alt={item.alt} />
          </a>
        ))}
      </div>
    </motion.div>
  );
};
