import { animate, motion, useAnimationFrame, useInView, useMotionValue } from 'framer-motion';
import { forwardRef, useEffect, useRef, useState, WheelEvent } from 'react';

import { fadeInProps } from '../animations';
import { getMaxScrollWidth } from './NewCarouselSection';
import { cn } from 'util/cn';

export const NewDesktopSection = forwardRef(
  (
    {
      heading,
      children,
      className,
    }: { heading: React.ReactNode; children: React.ReactNode; className?: string },
    ref: React.Ref<HTMLDivElement>
  ) => {
    const dragContainerRef = useRef<HTMLDivElement>(null);
    const [isDragging, setIsDragging] = useState(false);

    const x = useMotionValue(0);
    const prevTimestamp = useRef(0);
    const isAnimatingToBeginning = useRef(false);
    const [isAnimationStarted, setIsAnimationStarted] = useState(false);

    useAnimationFrame(timestamp => {
      const msPerFrame = timestamp - prevTimestamp.current;
      prevTimestamp.current = timestamp;

      if (!isAnimationStarted) return;

      const scrollSpeed = 50;
      const delta = (scrollSpeed * msPerFrame) / 1000;
      const maxScrollWidth = getMaxScrollWidth(dragContainerRef);
      const newX = Math.max(x.get() - delta, -maxScrollWidth);
      x.set(newX);

      const isAtEnd = x.get() <= -maxScrollWidth;
      if (isAtEnd && !isAnimatingToBeginning.current) {
        isAnimatingToBeginning.current = true;
        setTimeout(() => {
          animate(x, 0, {
            duration: 0.5,
            onComplete: () => {
              isAnimatingToBeginning.current = false;
            },
          });
        }, 3000);
      }
    });

    const isInView = useInView(dragContainerRef, { once: true });
    useEffect(() => {
      if (isInView) {
        setTimeout(() => {
          setIsAnimationStarted(true);
        }, 1000);
      }
    }, [isInView]);

    // This is required to prevent carousel items from stealing the drag event
    useEffect(() => {
      const dragContainer = dragContainerRef.current;
      if (dragContainer) {
        const makeImagesNotDraggable = () => {
          dragContainer.querySelectorAll('a, img').forEach(a => {
            if (a instanceof HTMLAnchorElement || a instanceof HTMLImageElement) {
              a.draggable = false;
            }
          });
        };

        makeImagesNotDraggable();

        const observer = new MutationObserver(makeImagesNotDraggable);
        observer.observe(dragContainer, { childList: true, subtree: true });

        return () => observer.disconnect();
      }
    }, []);

    // Prevent back navigation on iOS
    useEffect(() => {
      document.body.style.overscrollBehavior = 'contain';
      document.documentElement.style.overscrollBehavior = 'contain';

      return () => {
        document.body.style.overscrollBehavior = '';
        document.documentElement.style.overscrollBehavior = '';
      };
    }, []);

    const handleWheel = (event: WheelEvent) => {
      event.preventDefault();

      const scrollSpeed = 2;
      if (Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
        const newX = x.get() - event.deltaX * scrollSpeed;
        const maxScrollWidth = getMaxScrollWidth(dragContainerRef);
        x.set(Math.max(Math.min(newX, 0), -maxScrollWidth));
      }
    };

    return (
      <motion.div
        ref={ref}
        className={cn('w-full font-syne font-bold flex uppercase border-t', className)}
        {...fadeInProps}
      >
        <div className="flex flex-col flex-shrink-0 gap-3 p-6 w-[300px] justify-center text-3">
          {heading}
        </div>
        <motion.div className="flex-1 flex overflow-x-hidden" ref={dragContainerRef}>
          <motion.div
            className="flex flex-1 cursor-grab !touch-none"
            drag="x"
            style={{ x }}
            dragElastic={0.5}
            dragTransition={{ timeConstant: 200, power: 0.5 }}
            onClickCapture={e => {
              if (isDragging) {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
            onDragStart={e => {
              setIsDragging(true);
            }}
            onDragEnd={e => {
              setTimeout(() => {
                setIsDragging(false);
              }, 100);
            }}
            onWheel={handleWheel}
            dragConstraints={dragContainerRef}
          >
            {children}
          </motion.div>
        </motion.div>
      </motion.div>
    );
  }
);
