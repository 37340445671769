import _ from 'lodash';
import { useEffect, useRef } from 'react';

import {
  LayoutSingleColumn,
  ListingCard,
  MobileBottomNavigation,
  NamedLink,
  Page,
  PrimaryButton,
} from '../../components';
import FooterContainer from '../FooterContainer/FooterContainer';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FollowingSection from './components/FollowingSection/FollowingSection';
import HeroCollectionSection from './components/HeroCollectionSection/HeroCollectionSection';
import ListingsSection from './components/ListingsSection/ListingsSection';
import NewBlackSection from './components/NewBlackSection';
import { NewCarouselSection } from './components/NewCarouselSection';
import { NewFeaturedSection } from './components/NewFeaturedSection';
import { NewIconsSection } from './components/NewIconsSection';
import NewInstagramSection from './components/NewInstagramSection';
import NewListingsSection from './components/NewListingsSection';
import NewMatrixSection from './components/NewMatrixSection';
import NewSampleSaleSection from './components/NewSampleSaleSection';
import NewTrendingNowSection from './components/NewTrendingNowSection';
import ShowcaseCarousel from './components/ShowcaseCarousel/ShowcaseCarousel';
import css from './CustomLandingPage.module.css';
import { ConditionType } from 'config/configListing';
import { OurCommitments } from 'containers/ListingPage/ListingPage';
import { PIFERI_BRAND_ID } from 'containers/PiferiLandingPage/PiferiLandingPage';
import { useGetFavouriteListings } from 'hooks/api/listings/useGetFavouriteListings';
import { useGetListings } from 'hooks/api/listings/useGetListings';
import { useIsScrollingDisabled } from 'hooks/selectors/useIsScrollingDisabled';
import { useMediaQueries } from 'hooks/useMediaQueries';
import { DEFAULT_SORTING } from 'util/constants';
import { sendGa4Event } from 'util/GA4Events';

const CustomLandingPage = () => {
  const scrollingDisabled = useIsScrollingDisabled();

  useEffect(() => {
    sendGa4Event('home_page_visited', {});
  }, []);

  const { data: listingsData } = useGetListings({});
  const allListings = listingsData?.pages.flatMap(page => page.listings) || [];
  const latestListings = allListings.slice(0, 24);

  const { data: sampleListingsData } = useGetListings({
    condition: ConditionType.SampleSale,
    availability: 'hide_sold',
  });
  const sampleListings = _.sampleSize(
    sampleListingsData?.pages.flatMap(page => page.listings) || [],
    24
  );

  const { data: favouritesData } = useGetFavouriteListings({});
  const favouriteListings = favouritesData?.pages.flatMap(page => page.listings) || [];
  const isMobile = !useMediaQueries({ viewport: 'small' });
  const carouselRef = useRef<HTMLDivElement>(null);

  return (
    <Page title="THE NOLD" scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn topbar={<TopbarContainer />} footer={<MobileBottomNavigation />}>
        <div className={css.wrapper}>
          <FollowingSection />
          <NewMatrixSection
            onClickYes={() => {
              carouselRef.current?.scrollIntoView({ behavior: 'smooth' });
            }}
          />
          <NewCarouselSection
            ref={carouselRef}
            title="Luxury Faves"
            collectionId={6}
            subtitle="PRE-LOVED FAVES"
            items={[
              {
                title: 'Hermès',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__Lux - Hermes_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__Lux - Hermes_blur.webp',
                searchParams: `brandIds=4058`,
              },
              {
                title: 'Bottega Veneta',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__Lux - Bottega_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__Lux - Bottega_blur.webp',
                searchParams: `brandIds=3509`,
              },
              {
                title: 'Loewe',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__Lux - Loewe_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__Lux - Loewe_blur.webp',
                searchParams: `brandIds=2878`,
              },
              {
                title: 'Gucci',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__Lux - Gucci_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__Lux - Gucci_blur.webp',
                searchParams: `brandIds=4021`,
              },
              {
                title: 'Prada',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__Lux - Prada_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__Lux - Prada_blur.webp',
                searchParams: `brandIds=2857,4836,4837`,
              },
              {
                title: 'Celine',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__Lux - Celine_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__Lux - Celine_blur.webp',
                searchParams: `brandIds=7287`,
              },
              {
                title: 'The Row',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__Lux - The Row_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__Lux - The Row_blur.webp',
                searchParams: `brandIds=2957`,
              },
              {
                title: 'Dior',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__Lux - Dior_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__Lux - Dior_blur.webp',
                searchParams: `brandIds=6375`,
              },
              {
                title: 'Miu Miu',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__Lux - Miu Miu_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__Lux - Miu Miu_blur.webp',
                searchParams: `brandIds=4561`,
              },
              {
                title: 'Tory Burch',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__Lux - Tory Burch_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__Lux - Tory Burch_blur.webp',
                searchParams: `brandIds=2855`,
              },
              {
                title: 'Louis Vuitton',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__Lux - Louis Vuitton_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__Lux - Louis Vuitton_blur.webp',
                searchParams: `brandIds=4380`,
              },
            ]}
          />
          <NewCarouselSection
            title="Hight Street"
            collectionId={7}
            subtitle="PRE-LOVED FAVES"
            items={[
              {
                title: 'Massimo Dutti',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__High - Massimo Dutti_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__High - Massimo Dutti_blur.webp',
                searchParams: `brandIds=5732`,
              },
              {
                title: 'Nike',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__High - NIke_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__High - NIke_blur.webp',
                searchParams: `brandIds=3152`,
              },
              {
                title: 'Self-Portrait',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__High - Self Protrait_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__High - Self Protrait_blur.webp',
                searchParams: `brandIds=5047`,
              },
              {
                title: 'Adidas',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__High - Adidas_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__High - Adidas_blur.webp',
                searchParams: `brandIds=3153`,
              },
              {
                title: 'Ganni',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__High - Ganni_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__High - Ganni_blur.webp',
                searchParams: `brandIds=2865`,
              },
              {
                title: 'Samsoe and Samsoe',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__High - Samsoe and Samsoe_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__High - Samsoe and Samsoe_blur.webp',
                searchParams: `brandIds=7717,2932`,
              },
              {
                title: 'Reformation',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__High - Reformation_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-24_10-56-55__High - Reformation_blur.webp',
                searchParams: `brandIds=3078`,
              },
              {
                title: 'Max Mara',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__High - Max Mara_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__High - Max Mara_blur.webp',
                searchParams: `brandIds=2864`,
              },
              // {
              //   title: 'A.P.C.',
              //   imageUrl:
              //     'https://cdn.thenold.com/images/2024-10-25_11-37-37__High - APC_compressed.webp',
              //   imageBlurUrl:
              //     'https://cdn.thenold.com/images/2024-10-25_11-37-37__High - APC_blur.webp',
              //   searchParams: `brandIds=5525`,
              // },
              {
                title: 'Sandro',
                imageUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__High - Sandro_compressed.webp',
                imageBlurUrl:
                  'https://cdn.thenold.com/images/2024-10-25_11-37-37__High - Sandro_blur.webp',
                searchParams: `brandIds=2858`,
              },
            ]}
          />
          <NewTrendingNowSection />
          <NewIconsSection />
          <NewSampleSaleSection />
          <NewBlackSection
            title={
              <>
                THE NOLD IS WHERE
                <br />
                YOU{' '}
                <span className="px-1 bg-custom-green text-black">
                  <span className="line-through">SHOP</span> SCALE
                </span>{' '}
                THE
                <br />
                CHANGE WE NEED
              </>
            }
            sentences={[
              'AI LISTING? THE FASTEST GLOBALLY',
              '100% PAYOUT? YES!',
              "BRANDS' SAMPLE SALES",
              'LOVE FOR THE PLANET',
            ]}
            ctaText="Learn more"
            ctaLink="https://intercom.help/nold-b5f233066ddb/en/"
          />
          <NewListingsSection listings={latestListings} className="py-10" />
          <NewFeaturedSection />
          <NewBlackSection
            title="THE NOLD FOR BRANDS"
            sentences={[
              '360 SOLUTIONS FLEXIBILITY',
              'EARN FROM EVERY RESALE',
              'PRE-LOVED MARKET INSIGHTS',
              'BE A CIRCULARITY PIONEER',
            ]}
            ctaText="Learn more"
            ctaLink="https://www.forbes.com/sites/brookerobertsislam/2024/07/17/how-startup-the-nold-uses-ai--sustainability-to-drive-gen-z-resale/"
          />
          <NewInstagramSection className="pb-10" />
          {!isMobile && (
            <>
              {/* <ShowcaseCarousel
                title={
                  <span>
                    OUR BIGGEST <br className="md:hidden" /> SAMPLE SALE
                  </span>
                }
                // itemClassName={css.showCaseItem__sampleSales}
                itemClassName={css.showCaseItem__collections}
                items={[
                  {
                    title: <span className="text-white">Piferi</span>,
                    imgSrc: 'https://cdn.thenold.com/images/samplesale_31july_4_compressed.webp',
                    imgBlurSrc: 'https://cdn.thenold.com/images/samplesale_31july_4_blur.webp',
                    button: (
                      <NamedLink
                        name="SearchPage"
                        to={{
                          search: `brandIds=${PIFERI_BRAND_ID}&condition=${ConditionType.SampleSale}&sortBy=${DEFAULT_SORTING}`,
                        }}
                      >
                        <PrimaryButton>Shop now</PrimaryButton>
                      </NamedLink>
                    ),
                  },
                  {
                    title: 'Pangaia',
                    imgSrc: 'https://cdn.thenold.com/images/samplesale_31july_5_compressed.webp',
                    imgBlurSrc: 'https://cdn.thenold.com/images/samplesale_31july_5_blur.webp',
                    button: (
                      <NamedLink
                        name="SearchPage"
                        to={{
                          search: `brandIds=2885&condition=${ConditionType.SampleSale}&sortBy=${DEFAULT_SORTING}`,
                        }}
                      >
                        <PrimaryButton>Shop now</PrimaryButton>
                      </NamedLink>
                    ),
                  },
                  {
                    title: 'By Far',
                    imgSrc: 'https://cdn.thenold.com/images/samplesale_31july_6_compressed.webp',
                    imgBlurSrc: 'https://cdn.thenold.com/images/samplesale_31july_6_blur.webp',
                    button: (
                      <NamedLink
                        name="SearchPage"
                        to={{
                          search: `brandIds=2854&condition=${ConditionType.SampleSale}&sortBy=${DEFAULT_SORTING}`,
                        }}
                      >
                        <PrimaryButton>Shop now</PrimaryButton>
                      </NamedLink>
                    ),
                  },
                ]}
              /> */}
              {/* {Boolean(sampleListings?.length) && (
                <ListingsSection title="Shop all">
                  {sampleListings.map(l => (
                    <ListingCard key={l.id.uuid} listing={l} />
                  ))}
                </ListingsSection>
              )} */}
              {/* <ShowcaseCarousel
                title="Top Collections"
                items={[
                  {
                    title: 'Bags',
                    imgSrc: 'https://cdn.thenold.com/home/BAGS.jpg',
                    imgBlurSrc: 'https://cdn.thenold.com/home/BAGS.blur.jpg',
                    linkParams: {
                      name: 'SearchPage',
                      to: { search: `category=bags&sortBy=${DEFAULT_SORTING}` },
                    },
                  },
                  {
                    title: 'Clothes',
                    imgSrc: 'https://cdn.thenold.com/home/CLOTHES.jpg',
                    imgBlurSrc: 'https://cdn.thenold.com/home/CLOTHES.blur.jpg',
                    linkParams: {
                      name: 'SearchPage',
                      to: { search: `category=clothing&sortBy=${DEFAULT_SORTING}` },
                    },
                  },
                  {
                    title: 'Accessories',
                    imgSrc: 'https://cdn.thenold.com/home/ACCESSORIES.jpg',
                    imgBlurSrc: 'https://cdn.thenold.com/home/ACCESSORIES.blur.jpg',
                    linkParams: {
                      name: 'SearchPage',
                      to: { search: `category=accessories&sortBy=${DEFAULT_SORTING}` },
                    },
                  },
                  {
                    title: 'Shoes',
                    imgSrc: 'https://cdn.thenold.com/home/SHOES.jpg',
                    imgBlurSrc: 'https://cdn.thenold.com/home/SHOES.blur.jpg',
                    linkParams: {
                      name: 'SearchPage',
                      to: { search: `category=shoes&sortBy=${DEFAULT_SORTING}` },
                    },
                  },
                ]}
                itemClassName={css.showCaseItem__collections}
              /> */}
              {/* <ShowcaseCarousel
                title="Icons"
                itemClassName={css.showCaseItem__icons}
                items={[
                  {
                    title: (
                      <span>
                        MARIA <span style={{ fontSize: 'var(--n-font-size-3)' }}>x</span>
                        <br />
                        THE NOLD
                      </span>
                    ),
                    imgSrc: 'https://cdn.thenold.com/home/icons/maria.jpg',
                    imgBlurSrc: 'https://cdn.thenold.com/home/icons/maria.blur.jpg',
                    linkParams: {
                      name: 'ProfilePage',
                      params: {
                        id: '660aeed8-a6fe-4871-98c2-3f1d96d43a87',
                      },
                    },
                  },
                  {
                    title: (
                      <span style={{ color: 'var(--n-color-black)' }}>
                        MOLLIE{' '}
                        <span
                          style={{
                            fontSize: 'var(--n-font-size-3)',
                            color: 'var(--n-color-black)',
                          }}
                        >
                          x
                        </span>
                        <br />
                        THE NOLD
                      </span>
                    ),
                    imgSrc: 'https://cdn.thenold.com/home/icons/mollie.jpg',
                    imgBlurSrc: 'https://cdn.thenold.com/home/icons/mollie.blur.jpg',
                    linkParams: {
                      name: 'ProfilePage',
                      params: {
                        id: '66053581-9a48-4b01-824b-9f365dc920b1',
                      },
                    },
                  },
                  {
                    title: (
                      <span>
                        IVONA <span style={{ fontSize: 'var(--n-font-size-3)' }}>x</span>
                        <br />
                        THE NOLD
                      </span>
                    ),
                    imgSrc: 'https://cdn.thenold.com/home/icons/ivona.jpg',
                    imgBlurSrc: 'https://cdn.thenold.com/home/icons/ivona.blur.jpg',
                    linkParams: {
                      name: 'ProfilePage',
                      params: {
                        id: '6603fac8-062f-43da-81e0-f7a1497e592b',
                      },
                    },
                  },
                  {
                    title: (
                      <span>
                        DANIELLE <span style={{ fontSize: 'var(--n-font-size-3)' }}>x</span>
                        <br />
                        THE NOLD
                      </span>
                    ),
                    imgSrc: 'https://cdn.thenold.com/home/icons/danielle.jpg',
                    imgBlurSrc: 'https://cdn.thenold.com/home/icons/danielle.blur.jpg',
                    linkParams: {
                      name: 'ProfilePage',
                      params: {
                        id: '65f2fe35-15e2-4fa0-a8ac-f4a162dbe486',
                      },
                    },
                  },
                  {
                    title: (
                      <span>
                        LILY <span style={{ fontSize: 'var(--n-font-size-3)' }}>x</span>
                        <br />
                        THE NOLD
                      </span>
                    ),
                    imgSrc: 'https://cdn.thenold.com/home/icons/lily.jpg',
                    imgBlurSrc: 'https://cdn.thenold.com/home/icons/lily.blur.jpg',
                    linkParams: {
                      name: 'ProfilePage',
                      params: {
                        id: '65f86f23-33af-4593-ac87-81867cc58e6e',
                      },
                    },
                  },
                  {
                    title: (
                      <span>
                        AMELIA <span style={{ fontSize: 'var(--n-font-size-3)' }}>x</span>
                        <br />
                        THE NOLD
                      </span>
                    ),
                    imgSrc: 'https://cdn.thenold.com/home/icons/amelia.jpg',
                    imgBlurSrc: 'https://cdn.thenold.com/home/icons/amelia.blur.jpg',
                    linkParams: {
                      name: 'ProfilePage',
                      params: {
                        id: '65f9106d-fdf0-4394-a538-9835daf49c95',
                      },
                    },
                  },
                ]}
              /> */}
              {/* {Boolean(latestListings?.length) && (
                <ListingsSection title="Recently added listings">
                  {latestListings.map(l => (
                    <ListingCard key={l.id.uuid} listing={l} />
                  ))}
                </ListingsSection>
              )} */}
              {/* {Boolean(favouriteListings?.length >= 3) && (
                <ListingsSection title="Your Wishlist">
                  {favouriteListings.map(l => (
                    <ListingCard key={l.id.uuid} listing={l} />
                  ))}
                </ListingsSection>
              )} */}
              {/* <OurCommitments /> */}
            </>
          )}
        </div>
        <FooterContainer />
      </LayoutSingleColumn>
    </Page>
  );
};

export default CustomLandingPage;
